import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map} from "rxjs/internal/operators";
import {GLOBAL} from "./global";

@Injectable({
  providedIn: "root"
})
export class AuthServicePW {
  //Only for demo purpose
  authenticated = true;
    //para guardar los datos del user logeado
  public identity;
  public regaprov: boolean;
    //para guardar el token que pasara en cada una de las peticiones
  public token;
  //contendra la url
  public url: string;

  constructor(
      private store: LocalStoreService,
      private router: Router,
      private _http: HttpClient) {
    //le damos el valor que configuramos en el fichero de config global global.ts
    this.url = GLOBAL.url;
    this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }
  signout() {
    this.authenticated = false;
    this.store.setItem("demo_login_status", false);
    this.router.navigateByUrl("/sessions/signin");
  }


    //user_to_login sera el objeto con el que vamos a pasar un usuario para loguear email,password,getHash
    signup(user_to_login){
        //con let se define la variable y convertimos user_to_login a formato JSON
        let json = JSON.stringify(user_to_login);
        let params = "json="+json; //le concatenamos el string json que hemos generado
        /*usamos el servicio de _http.post para hacer peticion por post al metodo del API
        usamos la url que tenemos guardara el globla.url y concatenamos /login , pasamos los params para
        enviarle al metodo por post qu es el objeto convertido a json
        pasamos las cabeceras {headers:headers} y recogemos la respuesta con el metodo .map y la
        convertimos en un objeto json que podamos utilizar
        */

        //recogemos las respuestas del servicio de symfony y convertimos en json
        //definimos las cabeceras
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        //login2 es la action registrada en symfony
        return this._http.post(this.url + '/login2',
            params, {headers: headers}).pipe(map(res => res));
    }


    /**
     * Metodo que devuelve la variable regaprov, para saber si es un registro ya validado.
     * @returns {any}
     */
    getRegaprov(){
        //parsear el string guardado en localStorage
        let regaprov = JSON.parse(localStorage.getItem('regaprov'));
        //si es diferente a indefinido entonces:
        if(regaprov != "undefined")
        {
            this.regaprov = regaprov;
        }else{//si no
            this.regaprov = null;
        }
        return this.regaprov;
    }


    /**
     * Metodo que devuelve el identity del usuario logeado.
     * @returns {any}
     */
    getIdentity(){
        //parsear el string guardado en localStorage
        let identity = JSON.parse(localStorage.getItem('identity'));
        //si es diferente a indefinido entonces:
        if(identity != "undefined")
        {
            this.identity = identity;
            // console.log(this.identity)
        }else{//si no
            this.identity = null;
        }
        //devolvermos identity
        return this.identity;
    }


    /**
     * Metodo que devuelve el toquen del usuario para poder hacer peticiones a Symfony.
     * @returns {any}
     */
    getToken(){
        //parsear el string guardado en localStorage
        let token = JSON.parse(localStorage.getItem('token'));
        //si es diferente a indefinido entonces:
        if(token != "undefined")
        {
            this.token = token;
            // console.log(this.token)
        }else{//si no
            this.token = null;
        }
        //devolvermos token
        return this.token;
    }


    /**
     * Metodo para registrar un usuario web
     * @param user_to_register
     * @returns {any}
     */
    register(user_to_register){
        //convertimos el objeto a string de json
        let json = JSON.stringify(user_to_register);
        //le pasamos en un parametro por posttodo el obj json convertido en string
        let params = "json="+json;
        //definimos las cabeceras
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url + '/user/new',
            params, {headers: headers}).pipe(map(res => res));
    }

  /**
   * Metodo que devuelve la variable regaprov, para saber si es un registro ya validado.
   * @returns {any}
   */
  activateRegister(activationCode) {
//convertimos el objeto a string de json
//     let json = JSON.stringify(activationCode);
    //le pasamos en un parametro por posttodo el obj json convertido en string
    let params = "json=" + activationCode;
    // console.log("**********************************", params);
    //definimos las cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url + '/user/activation',
      params, {headers: headers}).pipe(map(res => res));
  }

  sendActivacionmsj(idusrweb){
    //le pasamos en un parametro por posttodo el obj json convertido en string
    let params = "idusrweb="+idusrweb;
    //definimos las cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url + '/sendActivacionmsj',
      params, {headers: headers}).pipe(map(res => res));

  }


  /**
   * Metodo para recuperar la cuenta mediante el reinicio de contrase;a
   * @param email
   * @returns {Observable<any>}
   */
  restartPwd(email){
    //Definimos los parametros
    let params = "email="+email;
    //Definimos las cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url + '/user/restarPassword',
      params, {headers: headers}).pipe(map(res => res));

  }


  /**
   * Metodo para confirmar el cambio de contrase;a
   * @param pwdtoken
   * @param password
   * @returns {Observable<any>}
   */
  confirRestartPwd(pwdtoken, password){
    //Definimos los parametros
    let params = "pwdtoken="+pwdtoken+"&password="+password;
    //Definimos las cabeceras
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url + '/user/confirRestarPwd',
      params, {headers: headers}).pipe(map(res => res));

  }

    /**
     * Metodo para verificar si el token es valido
     * @param token
     * @returns {Observable<any>}
     */
    checkSs(token){
        //Definimos los parametros
        let params = "authorization="+token;
        //Definimos las cabeceras
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url + '/user/checkss',
            params, {headers: headers}).pipe(map(res => res));
    }

}
