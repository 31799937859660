import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from 'rxjs';

export class Utils {
    static isMobile() {
        return window && window.matchMedia('(max-width: 767px)').matches;
    }
    static ngbDateToDate(ngbDate: { month, day, year }) {
        if (!ngbDate) {
            return null;
        }
        return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
    }
  static dateToNgbDate(date: Date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
    }
    static scrollToTop(selector: string) {
        if (document) {
            const element = <HTMLElement>document.querySelector(selector);
            element.scrollTop = 0;
        }
    }
    static genId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

  /**
   *
   * @param {NgbDateStruct} date
   * @param {boolean} ban si es true el formato sera YYYYMMDD si es false DD-MM-YYYY
   * @returns {string}
   */
    public formatDateSymfony(date: NgbDateStruct, ban: boolean): string {
      let srtformat:string;
      if (ban) {
        if (date.month.toString().length == 1) {
          srtformat = '0'.concat(date.month.toString());
        } else {
          srtformat = date.month.toString();
        }
        if (date.day.toString().length == 1) {
          srtformat = srtformat.concat('0'.concat(date.day.toString()));
        } else {
          srtformat = srtformat.concat(date.day.toString());
        }
        srtformat = date.year.toString().concat(srtformat);
      } else {
        if (date.day.toString().length == 1) {
          srtformat = '0'.concat(date.day.toString().concat('-'));
        } else {
          srtformat = date.day.toString().concat('-');
        }
        if (date.month.toString().length == 1) {
          srtformat = srtformat.concat('0'.concat(date.month.toString()).concat('-'));
        } else {
          srtformat = srtformat.concat(date.month.toString()).concat('-');
        }
        srtformat = srtformat.concat(date.year.toString());
      }
      return srtformat.toString();
    }



  /**
   *
   * @param dateNacimiento
   * @returns {any}
   */
    public menorMayorEdad(dateNacimiento){
      let mayorMenor;
      let d = new Date(dateNacimiento.year, dateNacimiento.month-1, dateNacimiento.day), now = new Date();
      let years = now.getFullYear() - d.getFullYear();
      d.setFullYear(d.getFullYear() + years);
      if (d > now) {
        years--;
        d.setFullYear(d.getFullYear() - 1);
      }
      let days = (now.getTime() - d.getTime()) / (3600 * 24 * 1000);
      let showAge =  Math.floor(years + days / (this.isLeapYear(now.getFullYear()) ? 366 : 365));
      if (showAge >= 18) {
        mayorMenor = true;
      } else {
        mayorMenor = false;
      }
      return mayorMenor;
    }

  /**
   *
   * @param year
   * @returns {boolean}
   */
  isLeapYear(year) {
    var d = new Date(year, 1, 28);
    d.setDate(d.getDate() + 1);
    return d.getMonth() == 1;
  }


  public dateToNgbdate(date: Date) {
    if (!date) {
      return null;
    }
    date = new Date(date);
    return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
  }

    public ngbDateToDate01(ngbDate: { month, day, year }) {
        if (!ngbDate) {
            return null;
        }
        return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
    }

    /** Retorna la fecha llevado a Local Date Peru en formato ISO, p.e. 2019-12-07. */
    static getStringLocalDate(fecha: Date): string {
      const tzoffset = new Date().getTimezoneOffset() * 60000;
      const ISOlocalDate = (new Date(fecha.getTime() - tzoffset)).toISOString().substr(0, 10);
      return ISOlocalDate;
  }

  static getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
      return new Promise((resolve, reject) => {
          let canvas = document.createElement('canvas');
          let img = new Image();
          img.onload = () => {
              canvas.height = img.height;
              canvas.width = img.width;
              canvas.getContext("2d").drawImage(img, 0, 0);
              resolve(canvas.toDataURL('image/png'));
          }
          img.onerror = () => reject('Imagen no disponible')
          img.src = localPath;
      })

  }

  static getImageDataUrlFromLocalPath(localPath: string): Observable<string> {
      return new Observable(observer => {
          let canvas = document.createElement('canvas');
          let img = new Image();
          img.onload = () => {
              canvas.height = img.height;
              canvas.width = img.width;
              canvas.getContext("2d").drawImage(img, 0, 0);
              observer.next(canvas.toDataURL('image/png'));
              observer.complete()

          }
          img.onerror = () => observer.error('Imagen no disponible');
          img.src = localPath;
      })
  }

 //
}
