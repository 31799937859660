<div class="app-footer">
	<div class="row">
		<div class="col-md-9">
			<!--<p><strong>Ministerio de Relaciones Exteriores</strong></p>-->
<!--			<p><strong>Gull - Angular Bootstrap Admin Dashboard Template</strong></p>-->
<!--			<p>Ministerio de Relaciones Exteriores</p>-->
		</div>
	</div>
	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
        <p>
          Calle El Pedregal, Blvd. Cancillería. Ciudad Merliot, Antiguo Cuscatlan, El Salvador. C.A.
          <br>
          PBX +503 2231-1000
        </p>

		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div>
				<!--<p class="m-0">&copy; 2020 Ministerio de Relaciones Exteriores</p>-->
				<p class="m-0"><strong>&copy; 2022 Ministerio de Relaciones Exteriores</strong></p>
				<p class="m-0"><strong>All rights reserved</strong></p>
			</div>
		</div>
	</div>
</div>
