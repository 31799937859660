import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import {HomeModule} from "./views/home/home.module";
import {RegistropersonaModule} from "./views/registropersona/registropersona.module";


const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'contacts',
      loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },

    {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'citas',
        loadChildren: () => import('./views/citas/citas.module').then(m => m.CitasModule)
    },

    {
        path: 'reg-familia',
        loadChildren: () => import('./views/reg-familia/reg-familia.module').then(m => m.RegFamiliaModule)
    },
    {
        path: 'reg-persona',
        loadChildren: () => import('./views/registropersona/registropersona.module').then(m => m.RegistropersonaModule)
    }
  ];

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'dashboard/v1',
  //   pathMatch: 'full'
  // },
    {
        path: '',
        redirectTo: 'sessions/signin',
        pathMatch: 'full'
    },
  {
    path: 'activation',
    redirectTo: 'sessions/activation',
    pathMatch: 'full'
  },{
    path: 'activate',
    redirectTo: 'sessions/activate',
    pathMatch: 'full'
  },
  {
    path: 'requisitos',
    redirectTo: 'info/requisitos',
    pathMatch: 'full'
  },

  {
    path: 'restart',
    redirectTo: 'sessions/restart',
    pathMatch: 'full'
  },


  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      },
      {
        path: 'info',
        loadChildren: () => import('./views/info/info.module').then(m => m.InfoModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
