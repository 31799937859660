import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthServicePW } from "./shared/services/auth.service";
import { SocialLoginModule, AuthService, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
// import { AuthServiceRS, AuthServiceConfigRS } from "./shared/services/authRS.service";
import { RecaptchaModule } from 'ng-recaptcha';
/**
 * MODULOS PARA INICIO DE SESION
 * config takes two params
 * 1. Provider config array
 * 2. Boolean to auto logged
 *
 * googleId 546251678596-r138jboikp80olmjlhvbdjh0nkschnh3.apps.googleusercontent.com
 * 738635671855-3ng2psimj5uen4qp3393fbrn3eq8nmt2.apps.googleusercontent.com
 */
const CONFIG = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    // provider: new GoogleLoginProvider('546251678596-sekrcmgs7649g8mos0d3lb02vpsnbsge.apps.googleusercontent.com')
    // provider: new GoogleLoginProvider('546251678596-sekrcmgs7649g8mos0d3lb02vpsnbsge.apps.googleusercontent.com')
    provider: new GoogleLoginProvider('632899826895-f51abqckndckcseerpkhn5kg0kbamls9.apps.googleusercontent.com')
  }
  // {
  //   id: FacebookLoginProvider.PROVIDER_ID,
  //   provider: new FacebookLoginProvider('728336654659464') //cuenta de fb sistemasutit.rree@gmail.com - 70701320
  //   // provider: new FacebookLoginProvider('244100013554494')
  // }
]);

export function provideConfig() {
  return CONFIG;
}

///////////////////////////// fin login
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    SocialLoginModule,
    RecaptchaModule,
  ],
  providers: [
    {provide: AuthServiceConfig,
      useFactory: provideConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
