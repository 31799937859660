export var GLOBAL = {
     //--- Ruta local ---
     //url: "http://localhost/Portalmovil/symfony/web/app_dev.php"
     //url: "http://localhost/portal_citas/symfony/web/app_dev.php"
     //--- Ruta servidor de desarrollo (aguilares) ---
    //  url: "http://aguilares.rree.gob.sv:8080/app.php"
      //---------bkchilanga -----------
     //url: "https://prepbe.rree.gob.sv/app.php"
     //---------desportal-----------
     //url: "https://despbe.rree.gob.sv"
     //---------produccion-----------
    url: "https://pbe.rree.gob.sv"
 };
