import { Component, OnInit } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthServicePW } from "src/app/shared/services/auth.service";
import {AuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider, AuthServiceConfig} from "angularx-social-login";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LocalStoreService} from "../../../../services/local-store.service";

@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
    public identity;
    public token;
    public rutaImagen = "./assets/images/faces/user.png";
    public lstres;
  user: SocialUser;
  private loggedIn: boolean;


  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthServicePW,
    private authServiceRS: AuthService,
    private router: Router,
    private store: LocalStoreService,
    private _route: ActivatedRoute
  ) {
    this.notifications = [
      {
        icon: "i-Speach-Bubble-6",
        title: "New message",
        badge: "3",
        text: "James: Hey! are you busy?",
        time: new Date(),
        status: "primary",
        link: "/chat"
      },
      {
        icon: "i-Receipt-3",
        title: "New order received",
        badge: "$4036",
        text: "1 Headphone, 3 iPhone x",
        time: new Date("11/11/2018"),
        status: "success",
        link: "/tables/full"
      },
      {
        icon: "i-Empty-Box",
        title: "Product out of stock",
        text: "Headphone E67, R98, XL90, Q77",
        time: new Date("11/10/2018"),
        status: "danger",
        link: "/tables/list"
      },
      {
        icon: "i-Data-Power",
        title: "Server up!",
        text: "Server rebooted successfully",
        time: new Date("11/08/2018"),
        status: "success",
        link: "/dashboard/v2"
      },
      {
        icon: "i-Data-Block",
        title: "Server down!",
        badge: "Resolved",
        text: "Region 1: Server crashed!",
        time: new Date("11/06/2018"),
        status: "danger",
        link: "/dashboard/v3"
      }
    ];

      this.identity = this.auth.getIdentity();
      this.token = this.auth.getToken();
  }

  ngOnInit() {
      this.expirasession();
    // this.authServiceRS.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = (user != null);
    // });
    if(this.identity.tusweProvider=='GOOGLE'){
      this.rutaImagen = this.identity.tuswePhotourl;
    }else{
      this.rutaImagen = this.identity.tuswePhotourl;
    }
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
      // this.auth.signout();
      this.logout();
  }

    //LOGOUT
    logout() {

        this.authServiceRS.signOut(true);
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        this.identity = null;
        this.token = null;
        this.auth.authenticated = false;
        this.store.setItem("demo_login_status", false);
        this.router.navigateByUrl("/sessions/signin");
    }

    /**
     * Metodo para redireccionar al logeo cuando la session haya expirado
     */
    expirasession(){
        this.auth.checkSs(this.token).subscribe((res: any[]) => {
            this.lstres = res;
            if (this.lstres.status == 'error') {
                this.logout();
            }
        });
        if (this.identity == undefined || this.identity == null
            || (this.token == undefined || this.token == null)){
            this.logout();
        }
    }
}
